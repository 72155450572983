<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Ubah Pengalaman
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer
        ref="formRules"
        v-slot="{ invalid }"
      >
        <b-row>
          <b-col md="8">
            <!-- form -->
            <b-form>
              <b-row>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      SK (Opsional)
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="SK"
                    >
                      <b-form-input
                        v-model="sk"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Partner <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Partner"
                      rules="required"
                    >
                      <v-select
                        v-model="partnerId"
                        label="name"
                        :reduce="option => option.id"
                        :options="listPartner"
                        placeholder="Pilih Partner"
                        @search="(search) => search.length > 0 ? onSearchPartner(search) : ''"
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Skill Role <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Skill Role"
                      rules="required"
                    >
                      <v-select
                        v-model="skillId"
                        label="name"
                        :reduce="option => option"
                        :options="listSkill"
                        placeholder="Pilih Role"
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Keahlian
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Skill Role"
                    >
                      <v-select
                        v-model="skill"
                        label="name"
                        :options="listOthersSkill"
                        placeholder="Tambahkan Keahlian"
                        :selectable="skill => !skills.map(e => e.id).includes(skill.id)"
                        @input="handleInput($event)"
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="skills.length > 0"
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      <div />
                    </template>
                    <div class="d-flex flex-wrap gap-[12px] mb-1">
                      <div
                        v-for="(e, i) in skills"
                        :key="i"
                        class="flex items-center px-1 py-[5px] gap-1 rounded-full bg-[#FCD4BE] text-black"
                      >
                        <span>{{ e.name }}</span>
                        <span
                          class="k-close text-[25px] cursor-pointer"
                          @click="skills.splice(i, 1)"
                        />
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="skillId && skillId.name === 'Customer Service'"
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Closing Rate
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Closing Rate"
                      rules="numeric|max_value:100"
                    >
                      <div class="flex items-center border rounded-md h-[37px]">
                        <b-form-input
                          v-model.number="closingRate"
                          class="border-0 h-[35px]"
                          placeholder="Jumlah closing rate customer service"
                        />
                        <span class="border-l px-1 text-xl align-middle leading-loose">%</span>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="(skillId && skillId.name === 'Admin Marketplace') || skillId === 'Admin Marketplace'"
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Platform
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Platform"
                    >
                      <v-select
                        v-model="platform"
                        label="platform_name"
                        :options="listPlatform"
                        placeholder="Pilih platform"
                        :selectable="platform => !platforms.map(e => e.platform_name).includes(platform.platform_name)"
                        @input="selectPlatform($event)"
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="skillId && skillId.name === 'Admin Marketplace'"
                  md="12"
                  class="mb-2"
                >
                  <b-col class="border-2 py-2 border-dashed rounded-lg pr-2 pl-0">
                    <h4 class="font-semibold pl-2">
                      Performa Chat
                    </h4>
                    <p
                      v-if="platforms.length === 0"
                      class="text-center text-muted mt-2"
                    >
                      Tambahkan platform yang digunakan
                    </p>
                    <b-form-group
                      v-for="(platform, index) in platforms"
                      :key="index"
                      label-cols="4"
                      label-class="font-medium text-7 text-black !pl-[26px] mr-[2px]"
                    >
                      <template #label>
                        {{ platform.platform_name }}
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        :name="'Platform ' + platform.platform_name"
                        :rules="{
                          required: true,
                          numeric: true,
                          min_value: 1,
                          max_value: platform.value_type === '%' ? 100 : platform.value_type === 'rate' ? 15 : 500,
                        }"
                      >
                        <div class="d-flex items-center gap-[10px]">
                          <div class="flex items-center border rounded pr-1 w-100">
                            <b-form-input
                              v-model.number="platform.value"
                              placeholder="Masukkan jumlah performa chat"
                              type="number"
                              class="!border-r !border-y-0 !border-l-0 !rounded-none"
                            />
                            <div class="pl-1 h-full">
                              {{ platform.value_type }}
                            </div>
                          </div>
                          <span
                            class="k-minus-cirlce cursor-pointer"
                            @click="platforms.splice(index, 1)"
                          />
                        </div>
                        <small class="text-danger d-block">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Sektor Bisnis <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="sektor Bisnis"
                      rules="required"
                    >
                      <v-select
                        v-model="sectorBisnisId"
                        label="partner_category_name"
                        :reduce="option => option.id"
                        :options="listSectorBisnis"
                        placeholder="Pilih Sektor Bisnis"
                      />
                      <small class="text-danger d-block">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Tanggal Mulai <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggal Mulai"
                      :rules="'required'"
                    >
                      <flat-pickr
                        v-model="startDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'j F Y',
                          dateFormat: 'Y-m-d',
                        }"
                        placeholder="Pilih Tanggal Mulai"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Tanggal Selesai <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggal Selesai"
                      :rules="'required'"
                    >
                      <flat-pickr
                        v-model="endDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'j F Y',
                          dateFormat: 'Y-m-d',
                        }"
                        placeholder="Pilih Tanggal Selesai"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click="$router.back({ query: { tab: 'experience' } })"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary w-[200px]"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import {
  required,
  min,
  minValue,
  max,
  alpha_spaces,
  numeric,
} from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { capitalize } from '@/libs/helpers'
import { listPlatform } from '../config'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
    BOverlay,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      capitalize,

      required,
      min,
      max,
      minValue,
      alpha_spaces,
      numeric,
      detailExperience: {},

      sk: '',
      startDate: null,
      closingRate: null,
      endDate: null,
      partnerId: null,
      skillId: null,
      skill: null,
      sectorBisnisId: null,
      stillWorking: false,
      platforms: [],
      platform: null,

      skills: [],
      listPartner: [],
      listSkill: [],
      listOthersSkill: [],
      listSectorBisnis: [],
      listPlatform,

      alertError,
      alertSuccess,
    }
  },
  async mounted() {
    this.getDetailExperience()
    this.getListSectorBisnis()
    this.getListSkills()
    this.getOthersSkills()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        partner_id: Number.isInteger(this.partnerId) ? this.partnerId : this.detailExperience.partner_id,
        skill_id: Number.isInteger(this.skillId.id) ? this.skillId.id : this.detailExperience.skill_id,
        business_sector_id: Number.isInteger(this.sectorBisnisId) ? this.sectorBisnisId : this.detailExperience.business_sector_id,
        start_date: this.startDate,
        end_date: this.endDate,
        is_still_working: 0,
        skill_ids: this.skills.map(e => e.id).join(','),
        sk: this.sk,
        closing_rate: this.closingRate,
        chat_performance: this.platforms,
      }

      const url = `v1/talent_experiences/${this.$route.params.id}/update`
      await komtimAxiosIns
        .put(url, data)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$router.back()
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    async getDetailExperience() {
      const url = `v1/talent_experiences/${this.$route.params.id}/detail`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailExperience = await data

          this.loadForm()
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    async getListSectorBisnis() {
      this.offset = 0
      const url = 'v1/business_sectors/resource?status=true'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listSectorBisnis = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkill = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getOthersSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=1`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listOthersSkill = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListPartner(keyword) {
      const params = `offset=0&limit=20&keyword=${keyword}`
      const url = `v1/partners?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listPartner = data
          this.listPartner = this.listPartner.map(item => {
            const result = {
              ...item,
              label: item.name,
            }
            return result
          })
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchPartner: _.debounce((search, it) => {
      it.getListPartner(search)
    }, 500),
    onSearchPartner(search) {
      this.searchPartner(search, this)
    },
    loadForm() {
      this.partnerId = this.detailExperience.partner_name
      this.sectorBisnisId = this.detailExperience.business_sector_name
      this.startDate = this.detailExperience.start_date
      this.endDate = this.detailExperience.end_date
      this.sk = this.detailExperience.sk
      this.closingRate = this.detailExperience.closing_rate
      this.platforms = this.detailExperience.chat_performance
      this.skills = this.detailExperience.skills.map(e => ({
        id: e.skill_id,
        name: e.skill_name,
      }))
      this.skillId = {
        id: this.detailExperience.skill_id,
        name: this.detailExperience.skill_name,
      }
    },
    handleInput(evt) {
      this.skills.push(evt)
      this.skill = null
    },
    selectPlatform(evt) {
      this.platforms.push(evt)
      this.platform = null
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
