var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',{staticClass:"p-[11px]"},[_c('div',{staticClass:"text-black text-2xl font-semibold mb-1"},[_vm._v(" Ubah Pengalaman ")]),_c('b-overlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('validation-observer',{ref:"formRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" SK (Opsional) ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"SK"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.sk),callback:function ($$v) {_vm.sk=$$v},expression:"sk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Partner "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Partner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.listPartner,"placeholder":"Pilih Partner"},on:{"search":function (search) { return search.length > 0 ? _vm.onSearchPartner(search) : ''; }},model:{value:(_vm.partnerId),callback:function ($$v) {_vm.partnerId=$$v},expression:"partnerId"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Skill Role "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Skill Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option; },"options":_vm.listSkill,"placeholder":"Pilih Role"},model:{value:(_vm.skillId),callback:function ($$v) {_vm.skillId=$$v},expression:"skillId"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Keahlian ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Skill Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.listOthersSkill,"placeholder":"Tambahkan Keahlian","selectable":function (skill) { return !_vm.skills.map(function (e) { return e.id; }).includes(skill.id); }},on:{"input":function($event){return _vm.handleInput($event)}},model:{value:(_vm.skill),callback:function ($$v) {_vm.skill=$$v},expression:"skill"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.skills.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div')]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex flex-wrap gap-[12px] mb-1"},_vm._l((_vm.skills),function(e,i){return _c('div',{key:i,staticClass:"flex items-center px-1 py-[5px] gap-1 rounded-full bg-[#FCD4BE] text-black"},[_c('span',[_vm._v(_vm._s(e.name))]),_c('span',{staticClass:"k-close text-[25px] cursor-pointer",on:{"click":function($event){return _vm.skills.splice(i, 1)}}})])}),0)])],1):_vm._e(),(_vm.skillId && _vm.skillId.name === 'Customer Service')?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Closing Rate ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Closing Rate","rules":"numeric|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center border rounded-md h-[37px]"},[_c('b-form-input',{staticClass:"border-0 h-[35px]",attrs:{"placeholder":"Jumlah closing rate customer service"},model:{value:(_vm.closingRate),callback:function ($$v) {_vm.closingRate=_vm._n($$v)},expression:"closingRate"}}),_c('span',{staticClass:"border-l px-1 text-xl align-middle leading-loose"},[_vm._v("%")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),((_vm.skillId && _vm.skillId.name === 'Admin Marketplace') || _vm.skillId === 'Admin Marketplace')?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Platform ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"platform_name","options":_vm.listPlatform,"placeholder":"Pilih platform","selectable":function (platform) { return !_vm.platforms.map(function (e) { return e.platform_name; }).includes(platform.platform_name); }},on:{"input":function($event){return _vm.selectPlatform($event)}},model:{value:(_vm.platform),callback:function ($$v) {_vm.platform=$$v},expression:"platform"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.skillId && _vm.skillId.name === 'Admin Marketplace')?_c('b-col',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('b-col',{staticClass:"border-2 py-2 border-dashed rounded-lg pr-2 pl-0"},[_c('h4',{staticClass:"font-semibold pl-2"},[_vm._v(" Performa Chat ")]),(_vm.platforms.length === 0)?_c('p',{staticClass:"text-center text-muted mt-2"},[_vm._v(" Tambahkan platform yang digunakan ")]):_vm._e(),_vm._l((_vm.platforms),function(platform,index){return _c('b-form-group',{key:index,attrs:{"label-cols":"4","label-class":"font-medium text-7 text-black !pl-[26px] mr-[2px]"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(platform.platform_name)+" ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":'Platform ' + platform.platform_name,"rules":{
                        required: true,
                        numeric: true,
                        min_value: 1,
                        max_value: platform.value_type === '%' ? 100 : platform.value_type === 'rate' ? 15 : 500,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{staticClass:"d-flex items-center gap-[10px]"},[_c('div',{staticClass:"flex items-center border rounded pr-1 w-100"},[_c('b-form-input',{staticClass:"!border-r !border-y-0 !border-l-0 !rounded-none",attrs:{"placeholder":"Masukkan jumlah performa chat","type":"number"},model:{value:(platform.value),callback:function ($$v) {_vm.$set(platform, "value", _vm._n($$v))},expression:"platform.value"}}),_c('div',{staticClass:"pl-1 h-full"},[_vm._v(" "+_vm._s(platform.value_type)+" ")])],1),_c('span',{staticClass:"k-minus-cirlce cursor-pointer",on:{"click":function($event){return _vm.platforms.splice(index, 1)}}})]),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)})],2)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Sektor Bisnis "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"sektor Bisnis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"partner_category_name","reduce":function (option) { return option.id; },"options":_vm.listSectorBisnis,"placeholder":"Pilih Sektor Bisnis"},model:{value:(_vm.sectorBisnisId),callback:function ($$v) {_vm.sectorBisnisId=$$v},expression:"sectorBisnisId"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tanggal Mulai "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tanggal Mulai","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                        altInput: true,
                        altFormat: 'j F Y',
                        dateFormat: 'Y-m-d',
                      },"placeholder":"Pilih Tanggal Mulai"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tanggal Selesai "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tanggal Selesai","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                        altInput: true,
                        altFormat: 'j F Y',
                        dateFormat: 'Y-m-d',
                      },"placeholder":"Pilih Tanggal Selesai"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"mr-50 button-secondary",attrs:{"type":"kembali"},on:{"click":function($event){return _vm.$router.back({ query: { tab: 'experience' } })}}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"mr-50 button-primary w-[200px]",attrs:{"disabled":invalid || _vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }